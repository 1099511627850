import React from 'react'
import { Link } from 'gatsby'
import ArticlePreview from './article-preview'

import styles from './recent-posts.module.scss'

export default ({ data }) => (
  <section id="blog">
    <div className={styles.postDetails}>
      <h2 className="section-headline">Recent Posts</h2>
    </div>
    <section className={styles.postList}>
      {data.map(({ node }) => {
        return (
            <ArticlePreview article={node} key={node.slug} />
        )
      })}
    </section>
    <a className={styles.postsLink} href="/blog">Read More of my articles</a>
  </section>
)
