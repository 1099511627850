import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.scss'

const ArticlePreview = ({ article }) => {
  return (
    <article className={styles.postItem + ' container'}>
      {/* <Img alt="" fluid={article.heroImage.fluid} /> */}
      <header>
        <h3 className={styles.postTitle}>
          <Link to={`/blog/${article.slug}`}>{article.title}</Link>
        </h3>
        <span className={styles.postMeta}>
          <svg style={{marginBottom: '-3px', marginRight: '3px'}} className="i-clock" viewBox="0 0 32 32"
            width="16" height="16" fill="none" stroke="currentcolor"
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="6.25%">
            <circle cx="16" cy="16" r="14"/>
            <path d="M16 8 L16 16 20 20"/>
          </svg>
          <span>{article.body.childMarkdownRemark.fields.readingTime.text} minute read</span>
          <span> :: {article.publishDate}</span>
        </span>
      </header>
      <div 
        className={styles.metaSummary}
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
      <Link className={styles.readMore} to={`/blog/${article.slug}`}>Read more about {article.title}</Link>
      {/* {article.tags &&
        article.tags.map(tag => (
          <p className="" key={tag}>
            {tag}
          </p>
        ))} */}
    </article>
  )
};

export default ArticlePreview;