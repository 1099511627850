import React from 'react'

import styles from './about.module.scss'

export default ({ data }) => (
    <section id="about" className={styles.about}>
        <div className={styles.userDetails}>
            <h1>My Expertise</h1>
        </div>
        <div className={styles.user}>
            <div className={styles.tech}>
                <h2>Code</h2>
                <i><img src="/icons/csharp.png" alt="C# icon" /></i>
                <i><img src="/icons/cplusplus.png" alt="C++ icon" /></i>
                <i><img src="/icons/rust.png" alt="Rust icon" /></i>
                <p>.Net Core is my primary focus. However, I am also well versed in C++ and I'm learning (and loving) Rust.</p>
            </div>
            <div className={styles.tech}>
                <h2>Web</h2>
                <i><img src="icons/sitecore.png" alt="Sitecore icon" /></i>
                <p>I have {new Date().getFullYear() - 2012} years of experience as a professional Sitecore developer. 
                I am currently the manager of Dev Operations & Standards at <a href="https://www.valtech.com" target="_blank" rel="noreferrer">Valtech Baltimore</a>.</p>
            </div>
            <div className={styles.tech}>
                <h2>Game Dev</h2>
                <i><img src="icons/unreal-engine.png" alt="UE4 icon"/></i>
                <i><img src="icons/unity.png" alt="Unity icon"/></i>
                <p>Experienced with Unreal Engine development (Blueprints and C++) and Unity 3D.</p>
            </div>
        </div>
    </section>
)